<template>
  <div v-if="userFarm !== undefined" class="p-3 w-full">
    <table v-if="userFarm !== undefined" class="w-full">
      <tr class="border-b-2 border-green-dark flex py-3">
        <td class="">
          <Input
            :error="errorCode === 'emailNotValid' ? true : false"
            errorMessage="Ugyldig e-mail"
            :label="t(`settings.companyName`)"
            class="mr-3"
            labelWidth="w-36"
            inputWidth="w-72"
            placeholder="Admin mail . . ."
            v-model="userFarm.companyName"
          />
        </td>
        <td class="">
          <Input
            :error="errorCode === 'emailNotValid' ? true : false"
            errorMessage="Ugyldig e-mail"
            :label="t(`settings.vat`)"
            class="w-full"
            labelWidth="w-28"
            inputWidth="w-44"
            placeholder="Admin mail . . ."
            v-model="userFarm.cvr"
          />
        </td>
      </tr>
      <tr class="border-b-2 border-green-dark flex w-full justify-between">
        <td class="">
          <NumberInput
            class="my-3"
            labelWidth="w-72"
            inputWidth="w-36"
            :label="t(`settings.lastDayWage`)"
            v-model="lastDate"
            min="1"
            max="28"
          />
        </td>
      </tr>
    </table>
  </div>
  <div
    v-if="
      userFarm !== undefined &&
        unitsList.length !== 0 &&
        !userFarm.multipleUnits
    "
    class="flex flex-col w-full"
  >
    <div class="p-6 flex flex-col">
      <div class="w-full flex justify-start items-center mb-12">
        <Checkbox
          :error="errorCode === 'emailNotValid' ? true : false"
          errorMessage="Ugyldig e-mail"
          :label="t(`settings.ownerRegistersTime`)"
          class=""
          v-model="unitsDataList[0].ownerRegistersTime"
          @change="updateOwnerRegistersTime(unitsList[0])"
        />
        <p class="ml-12 text-green">
          Ejeren vil få adgang til tidsregistrering, og vil blive vist under
          listen af ansatte.
        </p>
      </div>
      <div class="w-full flex flex-col mb-12">
        <div class="w-full flex justify-start items-center">
          <Checkbox
            :error="errorCode === 'emailNotValid' ? true : false"
            errorMessage="Ugyldig e-mail"
            icon="fa-wifi"
            :label="t(`settings.wifiCheck`)"
            class=""
            v-model="unitsDataList[0].usingIp"
            @change="updateUsingIp(unitsList[0])"
          />
        </div>

        <div
          v-if="unitsDataList[0].usingIp"
          class="w-full flex justify-start items-center mt-6"
        >
          <span
            class="flex flex-shrink-0 items-center text-2xl mx-6"
            :class="[wifiValid(unitsList[0]) ? 'text-green' : 'text-red-500']"
          >
            <i class="fas fa-wifi"></i>
          </span>
          <p v-if="!wifiValid(unitsList[0])" class="w-3/6">
            {{ t(`settings.wifiInvalid`) }}
          </p>
          <p v-if="wifiValid(unitsList[0])" class="w-3/6">
            {{ t(`settings.wifiValid`) }}
          </p>
          <Button
            v-if="unitsDataList[0].usingIp"
            :label="t(`settings.registerWifi`)"
            icon="fa-sync"
            class="bg-yellow w-2/6 mt-3"
            @click="submitUpdateIpAddress(unitsList[0])"
          />
        </div>
      </div>

      <div class="w-full flex flex-col">
        <div class="w-full flex justify-start items-center">
          <Checkbox
            :error="errorCode === 'emailNotValid' ? true : false"
            errorMessage="Ugyldig e-mail"
            icon="fa-map-marked-alt"
            :label="t(`settings.gpsCheck`)"
            class=""
            v-model="unitsDataList[0].usingGps"
            @change="updateUsingGps(unitsList[0])"
          />
        </div>

        <div
          v-if="unitsDataList[0].usingGps"
          class="w-full flex flex-wrap items-center mt-6 px-6"
        >
          <div
            :id="'map' + unitsList[0].id"
            class="h-72 w-full border-2 border-green rounded-md"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted, watch } from "vue";
import { farm } from "@/global/services/farm";
import { units } from "@/global/services/units";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import Checkbox from "@/components/Checkbox.vue";
import Input from "@/components/Input";
import NumberInput from "@/components/NumberInput";
import Button from "@/components/Button";
import { errorLogging } from "@/global/services/errorLogging";
import ipify from "ipify2";

import { useI18n } from "vue-i18n";

export default {
  name: "TimeRegistrationSettings",
  components: { Input, NumberInput, Checkbox, Button },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {},
        dk: {}
      }
    });
    const state = {
      errorCode: ref(""),
      newUnit: ref({}),
      unitModal: ref(false),
      unitsList: ref(units.getUnitsList()),
      unitsDataList: units.getUnitsDataList(true),
      userFarm: farm.getFarm(true),
      localPubIp: ref(""),

      lastDate: ref(1)
    };

    watchEffect(() => {
      if (state.userFarm.value !== undefined) {
        state.lastDate.value = state.userFarm.value.wagePeriodEndDate;
      }
    });

    watch(state.lastDate, () => {
      farm.set({ wagePeriodEndDate: parseInt(state.lastDate.value) });
    });

    ipify
      .ipv4()
      .then(ipv4 => (state.localPubIp.value = ipv4))
      .catch(() =>
        errorLogging.setWarning("Add-blocker forhindrer brug af Wifi Tjek")
      );

    const submitUpdateIpAddress = unit => {
      ipify
        .ipv4()
        .then(ipv4 => units.updateIpAddress(unit, ipv4))
        .catch(err => errorLogging.setError(err));
    };

    const wifiValid = unit => {
      let result = null;
      if (unit.data().ipAddress === state.localPubIp.value) {
        result = true;
      } else {
        result = false;
      }
      return result;
    };

    watchEffect(() => {
      if (!state.unitModal.value) {
        state.newUnit.value = {};
      }
    });

    const submitNewUnit = () => {
      const event1 = new CustomEvent("processing", { detail: true });
      document.dispatchEvent(event1);
      units
        .createUnit(state.newUnit.value)
        .then(message => {
          state.unitModal.value = false;
          const event2 = new CustomEvent("processing", { detail: false });
          document.dispatchEvent(event2);
          errorLogging.setSuccess(message);
        })
        .catch(message => {
          errorLogging.setError(message);
          const event2 = new CustomEvent("processing", { detail: false });
          document.dispatchEvent(event2);
        });
    };
    onMounted(() => {
      watch(
        state.unitsList.value,
        async () => {
          if (state.unitsList.value.length !== 0) {
            state.unitsList.value.forEach(unit => {
              if (unit.data().usingGps) {
                navigator.geolocation.getCurrentPosition(
                  geo => {
                    mapboxgl.accessToken =
                      "pk.eyJ1IjoiZW1pZWxqYiIsImEiOiJja2xjcGNiZG8wajRjMndtanZ0OWlvbnJuIn0.ye9DfLEqitSFvNVQewzczg";
                    let map;
                    if (unit.data().gpsBounds1) {
                      const drawnBounds1 = unit.data().gpsBounds1;
                      const drawnBounds2 = unit.data().gpsBounds2;
                      const drawnBounds = [
                        (drawnBounds1[0] + drawnBounds2[0]) / 2,
                        (drawnBounds1[1] + drawnBounds2[1]) / 2
                      ];
                      map = L.map("map" + unit.id).setView(drawnBounds, 16);
                    } else {
                      map = L.map("map" + unit.id).setView(
                        [geo.coords.latitude, geo.coords.longitude],
                        16
                      );
                    }

                    const baselayers = {
                      "Street map": L.tileLayer(
                        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
                        {
                          attribution:
                            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                          maxZoom: 18,
                          id: "mapbox/streets-v11",
                          tileSize: 512,
                          zoomOffset: -1,
                          accessToken:
                            "pk.eyJ1IjoiZW1pZWxqYiIsImEiOiJja2xjcGNiZG8wajRjMndtanZ0OWlvbnJuIn0.ye9DfLEqitSFvNVQewzczg"
                        }
                      ),
                      "Satellite map": L.tileLayer(
                        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
                        {
                          attribution:
                            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                          maxZoom: 18,
                          id: "mapbox/satellite-v9",
                          tileSize: 512,
                          zoomOffset: -1,
                          accessToken:
                            "pk.eyJ1IjoiZW1pZWxqYiIsImEiOiJja2xjcGNiZG8wajRjMndtanZ0OWlvbnJuIn0.ye9DfLEqitSFvNVQewzczg"
                        }
                      )
                    };

                    const overlays = {};

                    L.control.layers(baselayers, overlays).addTo(map);

                    baselayers["Street map"].addTo(map);

                    // FeatureGroup is to store editable layers
                    const drawnItems = new L.FeatureGroup();
                    map.addLayer(drawnItems);
                    const drawControl = new L.Control.Draw({
                      draw: {
                        circlemarker: false,
                        circle: false,
                        polyline: false,
                        polygon: false,
                        marker: false
                      },
                      edit: {
                        remove: true,
                        featureGroup: drawnItems
                      }
                    });

                    map.addControl(drawControl);
                    map.on(L.Draw.Event.CREATED, function(e) {
                      const corners = e.layer.getLatLngs();
                      const bounds = [
                        [corners[0][0].lat, corners[0][0].lng],
                        [corners[0][2].lat, corners[0][2].lng]
                      ];
                      units.updateGpsCoordinates(unit, bounds[0], bounds[1]);
                      const layer = e.layer;
                      // Do whatever else you need to. (save to db; add to map etc)
                      map.addLayer(layer);
                    });
                    if (unit.data().gpsBounds1 && unit.data().gpsBounds2) {
                      const Ebounds = [
                        unit.data().gpsBounds1,
                        unit.data().gpsBounds2
                      ];
                      const Erectangle = L.rectangle(Ebounds).addTo(map);
                    }
                  },
                  undefined,
                  {
                    enableHighAccuracy: true
                  }
                );
              }
            });
          }
        },
        {
          immediate: true
        }
      );
    });

    return {
      t,
      ...state,
      updateOwnerRegistersTime: units.updateOwnerRegistersTime,
      updateUsingMultipleUnits: farm.updateUsingMultipleUnits,
      submitNewUnit,
      updateUsingIp: units.updateUsingIp,
      updateUsingGps: units.updateUsingGps,
      submitUpdateIpAddress,
      wifiValid
    };
  }
};
</script>
